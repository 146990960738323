import './App.css';
import React, { useState, useEffect } from 'react';

  // Result Modal
  function ResultModal({ resultModalDisplayed, resultModalVisible, hideResultModal, resultContent }) {
    return (
      <section 
        className={resultModalVisible ? 'modal isVisible' : 'modal'}
        style={{ display: resultModalDisplayed ? 'flex' : 'none'}} 
        id="resultModal"
      >
        <div className="modal-overlay" onClick={hideResultModal} />
        <div 
          className="modal-content"
          dangerouslySetInnerHTML={{ __html: resultContent }}
        >
        </div>
      </section>
    );
  }

  export default ResultModal;