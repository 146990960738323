import './App.css';
import React, { useState, useEffect } from 'react';

  // Loading Modal
  function LoadingModal({ loadingModalDisplayed, loadingModalVisible }) {
    return (
      <section 
        className={loadingModalVisible ? "modal isVisible" : "modal"}
        style={{ display: loadingModalDisplayed ? 'flex' : 'none' }} 
        id="loadingModal"
      >
        <div className="modal-overlay" />
        <div className="modal-content">
          <p className="loading-text">Packing Your Bags... Just a Moment!</p>
        </div>
      </section>
    );
  }

  export default LoadingModal;