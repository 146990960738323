import logo from './img/logo.png';
import './App.css';
import React, { useState, useEffect } from 'react';

// Header
function Header({ showForm }) {
    return (
      <header className="header">
        <div className="container">
          <div className="logo-container">
            <img src={logo} alt="PergiYuk!" className="logo" />
          </div>
          <div className="hero-content">
            <h1 className="main-heading">
              Plan Your Perfect Getaway in Just a Few Clicks
            </h1>
            <p className="subtext">
              Ready to embark on your next adventure? Just tell us where you’re
              headed, how long you're staying, and your budget, and we'll craft the
              ultimate itinerary for you. Let's make your dream trip a reality!
            </p>
            <button className="cta-button" onClick={showForm}>
              Start Planning Now!
            </button>
          </div>
        </div>
      </header>
    );
  }

  export default Header;