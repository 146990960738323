import './App.css';
import React, { useState, useEffect } from 'react';

  // Form Section
  function FormSection({ isDisplayed, isVisible, hideForm, handleSubmit }) {
    return (
      <section
        className={isVisible ? 'form-section isVisible' : 'form-section'}
        id="formSection"
        style={{ display: isDisplayed ? 'flex':'none' }}
      >
            <div className="overlay" id="formSectionOverlay" onClick={hideForm}></div>
        <div className="container">
            <form 
              className="itinerary-form"
              onSubmit={handleSubmit}
            >
                <div className="form-group">
                    <label htmlFor="destination">Destination:</label>
                    <input type="text" id="destination" name="destination" className="input-field" required />
                </div>
                <div className="form-group">
                    <label htmlFor="budget">Travel Budget (IDR):</label>
                    <input type="number" id="budget" name="budget" className="input-field" required />
                </div>
                <div className="form-group">
                    <label htmlFor="days">Length of Stay (days):</label>
                    <input type="number" id="days" name="days" className="input-field" required />
                </div>
                <button type="submit" className="submit-button">Show Me My Adventure!</button>
            </form>
        </div>
      </section>
    );
  }

  export default FormSection;