import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Header from './Header.js';
import FormSection from './FormSection.js';
import LoadingModal from './LoadingModal.js';
import ResultModal from './ResultModal.js';
  
// The Landing Page
function LandingPage() {
    // State Declaration
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [loadingModalDisplayed, setloadingModalDisplayed] = useState(false);
    const [loadingModalVisible, setloadingModalVisible] = useState(false);
    const [resultModalDisplayed, setResultModalDisplayed] = useState(false);
    const [resultModalVisible, setResultModalVisible] = useState(false);
    const [resultContent, setResultContent] = useState();

    // Form Show Hide Functions
    const showForm = () => {
        setIsDisplayed(true);
        setTimeout(function() {
        setIsVisible(true);  
        }, 200);

        clearTimeout();
    }

    const hideForm = () => {
        setIsVisible(false);
        setTimeout(function() {
        setIsDisplayed(false);  
        }, 200);
    }

    // Form Submit Handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        setloadingModalDisplayed(true);
        setTimeout(function(){
        setloadingModalVisible(true);
        hideForm();
        },200);

        // Define to form data object
        const formData = {
            destination: event.target.destination.value,
            budget: event.target.budget.value,
            days: event.target.days.value
        };

        // API call
        try {
            const response = await axios.post('https://reynaldou.com/pergiyukapi/submit-form', formData);
            
            // Handle successful submission (e.g., show a success message)
            console.log(response.data);
            setResultContent(response.data.message);

            // Open result modal
            setloadingModalVisible(false);
            setTimeout(function(){
                setloadingModalDisplayed(false);
                setResultModalDisplayed(true);
                setTimeout(function(){
                    setResultModalVisible(true);
                },200);  
            },200);  
        } catch (error) {
            alert('Error submitting form');
            console.error('Error submitting form:', error);
            // Handle error (e.g., show an error message)
        }

        // Simulate Result Ready from API
        /*
        setTimeout(function(){
            setloadingModalVisible(false);
            setTimeout(function(){
                setloadingModalDisplayed(false);
                setResultModalDisplayed(true);
                setTimeout(function(){
                setResultModalVisible(true);
                },200);  
            },200);  
        },4000);
        */
    }

    // Hide Result Modal
    const hideResultModal = () => {
        setResultModalVisible(false);
        setTimeout(function(){
        setResultModalDisplayed(false);
        },200); 
    }

    return (
        <>
        <Header showForm={showForm}/>
        <FormSection 
            isDisplayed={isDisplayed}
            isVisible={isVisible} 
            hideForm={hideForm}
            handleSubmit={handleSubmit}
        />

        <LoadingModal 
            loadingModalDisplayed={loadingModalDisplayed} 
            loadingModalVisible={loadingModalVisible} 
        />
        <ResultModal 
            resultModalDisplayed={resultModalDisplayed} 
            resultModalVisible={resultModalVisible} 
            hideResultModal={hideResultModal}
            resultContent={resultContent}
        />
        </>
    );
}

export default LandingPage;