import './App.css';
import React, { useState, useEffect } from 'react';
import LandingPage from './LandingPage.js';

// Main
function App() {
  return (
    <LandingPage />
  );
}

export default App;
